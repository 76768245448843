import { Html5Qrcode } from "html5-qrcode";
import { getTicket } from "../api/ticket";
import formValidator from "../legacy/modules/a_FormValidator";
import { jsPDF } from "jspdf";

let ticketInterval;
let html5QrcodeScanner;
const startBtn = document.getElementById('js-qr-scan');
const qrScanFrame = document.getElementById('js-qr-scan-frame');
const stopBtn = document.getElementById('js-qr-scan-stop');
const qrControls = document.getElementById('js-qr-controls');
const paymenMethodtBtn = document.getElementById('js-payment-method-btn');
const paymentBtn = document.getElementById('js-payment-btn');
const spinner = document.getElementById('js-spinner');
const overlay = document.getElementById('js-overlay');
const form = document.querySelector('form[name="ticket"]');
const receiptBtn = document.getElementById('js-receipt-btn');
const downloadReceiptBtn = document.getElementById('js-download-receipt');
const formReceipt = document.querySelector('form[name="ticket_receipt_email"]');
const ticketViewDiv = document.getElementById('js-ticket-view');
const ticketUuid = ticketViewDiv ? ticketViewDiv.dataset.ticket : null;
const receiptStatus = ticketUuid ? ticketViewDiv.getAttribute('data-receipt'): null;

function qrScan() {
    if (startBtn) {
        startBtn.style.display = 'none';
    }

    if (qrControls) {
        qrControls.style.display = 'flex';
        qrScanFrame.style.display = 'flex';
        stopBtn.style.display = 'flex';
    }

    let qrboxFunction = function(viewfinderWidth, viewfinderHeight) {
        let minEdgePercentage = 0.7;
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
            width: qrboxSize,
            height: qrboxSize
        };
    };

    let config = { fps: 10, qrbox: qrboxFunction };

    html5QrcodeScanner.start({ facingMode: "environment" }, config, qrCodeSuccessCallback)
        .then(() => {
        })
        .catch((err) => {
            console.error(`Error iniciando el escaneo: ${err}`);
        });
}

async function qrCodeSuccessCallback(decodedText) {
    try {
        showSpinner();
        const data = {
            barcode: decodedText,
        };
        stopQrScan();
        const ticket = await getTicket(data);
        if (ticket.urlDetail) {
            window.location.href = ticket.urlDetail;
        } else {
            hideSpinner();
            showModal('ticket-not-found');
        }
    } catch (error) {
        hideSpinner();
        showModal('ticket-not-found');
    }
}

function handleFormSubmit(event) {
    if (!sendForm(event)) {
        return;
    }
    event.preventDefault();
    showSpinner();

    const formData = new FormData(form);

    fetch(form.action, {
        method: 'POST',
        body: formData,
    })
        .then(response => response.json())
        .then(data => {
            if (data.ticket) {
                window.location.href = data.ticket.urlDetail;
            } else {
                hideSpinner();
                showModal('ticket-not-found');
            }
        })
        .catch(error => {
            hideSpinner();
            showModal('ticket-not-found');
        });
}

function stopQrScan() {
    if (html5QrcodeScanner) {
        html5QrcodeScanner.stop().then(() => {
            html5QrcodeScanner.clear();
            qrScanFrame.style.display = 'none';
            stopBtn.style.display = 'none';
            startBtn.style.display = 'flex';
        }).catch(err => {
            console.log(`Error al parar el escaneo del código QR: ${err}`);
        });
    }
}

function showModal(modalName) {
    const modal = document.querySelector(`[data-modal="${modalName}"]`);
    if (modal) {
        modal.classList.add('is-active');
    }
}

function updateDataSelected(cardValue) {
    const modal = document.querySelector('.c-modal[data-modal="card-selected"]');
    if (modal) {
        modal.dataset.selected = cardValue;
    }
}

function updatePaymentLink(cardUuid) {
    if (paymentBtn) {
        const paymentLink = paymentBtn.querySelector('a.o-btn--primary');
        if (paymentLink) {
            const currentUrl = paymentLink.getAttribute('href');
            const newUrl = currentUrl.replace(/\/[^\/]+$/, `/${cardUuid}`);
            paymentLink.setAttribute('href', newUrl);
        }
    }
}

function closeModal(modalName) {
    const modal = document.querySelector(`[data-modal="${modalName}"]`);
    if (modal) {
        modal.classList.remove('is-active');
    }
}

function updatePaymentInfo(cardName, cardExpiryDate, cardUuid) {
    if (paymenMethodtBtn) {
        const cardExpiryElem = paymenMethodtBtn.querySelector('.c-ticket-detail__payment-expiry');
        const cardCardElem = paymenMethodtBtn.querySelector('.c-ticket-detail__payment-card');

        if (cardExpiryElem && cardCardElem) {
            cardExpiryElem.textContent = cardExpiryDate;
            cardCardElem.textContent = cardName;
        }
        updatePaymentLink(cardUuid);
    }
}

function showSpinner() {
    if (spinner) spinner.style.display = 'flex';
    if (overlay) overlay.style.display = 'block';
}

function hideSpinner() {
    if (spinner) spinner.style.display = 'none';
    if (overlay) overlay.style.display = 'none';
}

function sendForm(event) {
    const isValid = formValidator.validate(`#${event.target.id}`);
    if (isValid) {
        return true;
    }
    event.preventDefault();
    return false;
}

function sendReceiptForm(event) {
    const isValid = formValidator.validate(`#${event.target.id}`);
    if (isValid) {
        return true;
    }
    event.preventDefault();
    return false;
}

function downloadReceiptPdf() {
    const doc = new jsPDF();
    const receiptContent = document.querySelector('.c-modal__receipt').innerText;

    doc.setFont('helvetica');
    doc.setFontSize(8);

    const margin = 10;
    const pageWidth = doc.internal.pageSize.width;
    const textWidth = pageWidth - 2 * margin;
    const lineHeight = 5;

    function addCenteredText(text, yPosition) {
        const lines = doc.splitTextToSize(text, textWidth);
        lines.forEach((line, index) => {
            const lineWidth = doc.getTextWidth(line);
            const xPosition = (pageWidth - lineWidth) / 2;
            doc.text(line, xPosition, yPosition + (index * lineHeight));
        });
    }

    addCenteredText(receiptContent, margin);
    doc.save('recibo.pdf');
}

function checkTicketReceipt(uuid) {
    fetch(`/es/ticket-recibo/${uuid}`)
        .then(response => response.text())
        .then(receiptHtml => {
            if (receiptHtml && receiptHtml !== '[]') {
                ticketViewDiv.style.display = 'block';
                receiptHtml = receiptHtml.replace(/^"|"$/g, '');
                const formattedReceiptHtml = receiptHtml
                    .replace(/\\r\\n/g, '<br>')
                    .replace(/\r?\n/g, '<br>')
                    .replace(/\\\//g, '/')
                    .replace(/\\u[\dA-Fa-f]{4}/g, match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)))
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&amp;/g, '&');

                const receiptContainer = document.querySelector('.c-modal__receipt');
                if (receiptContainer) {
                    receiptContainer.innerHTML = formattedReceiptHtml;
                }
                hideSpinner();
                clearInterval(ticketInterval);
            }
        })
        .catch(error => console.error('Error fetching ticket:', error));
}

function init() {

    hideSpinner();
    if (document.getElementById('js-qr-scan-frame')) {
        html5QrcodeScanner = new Html5Qrcode("js-qr-scan-frame");
    }

    if (startBtn) {
        startBtn.addEventListener('click', (event) => {
            event.preventDefault();
            qrScan();
        });
    }

    if (stopBtn) {
        stopBtn.addEventListener('click', stopQrScan);
    }

    if (paymenMethodtBtn) {
        paymenMethodtBtn.addEventListener('click', (event) => {
            event.preventDefault();
            showModal('card-selected');
        });
    }

    if (receiptBtn) {
        receiptBtn.addEventListener('click', (event) => {
            event.preventDefault();
            showModal('receipt');
        });
    }

    if (downloadReceiptBtn) {
        downloadReceiptBtn.addEventListener('click', (event) => {
            event.preventDefault();
            downloadReceiptPdf();
        });
    }

    if (form) {
        form.addEventListener('submit', handleFormSubmit);
    }

    if (formReceipt) {
        formReceipt.addEventListener('submit', sendReceiptForm);
    }

    if (ticketUuid && receiptStatus === 'false') {
        showSpinner();
        ticketInterval = setInterval(() => checkTicketReceipt(ticketUuid), 5000);
    }

    document.querySelectorAll('.c-modal[data-modal="card-selected"] .o-btn--outline--primary').forEach(button => {
        button.addEventListener('click', (event) => {
            const cardValue = event.currentTarget.dataset.card;
            updateDataSelected(cardValue);
            const selectedCard = event.currentTarget.closest('tr').children;
            const cardName = selectedCard[0].textContent;
            const cardExpiryDate = selectedCard[1].textContent;
            updatePaymentInfo(cardName, cardExpiryDate, cardValue);
            closeModal('card-selected');
        });
    });

    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
            hideSpinner();
        }
    });
}

export default init();
