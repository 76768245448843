import {Ticket, TicketPayload} from "../interfaces/ticket";

export async function getTicket(data: TicketPayload): Promise<Ticket> {
    const landingSection = document.querySelector('.c-landing');
    const locale = landingSection ? landingSection.getAttribute('data-locale') : 'es';
    const url = `/${locale}/ticket/ticket-qr`;

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) throw new Error('ticket not found');
    const result = await response.json();

    if (Array.isArray(result) && result.length === 0) {
        throw new Error('ticket not found');
    }

    return result;
}