import formValidator from "../modules/a_FormValidator";
import { beginCheckoutProduct, clickProduct } from "../modules/gtagmanager";
import verify from "../modules/grecaptcha";
import { validateInputs } from "../components/form/form-inputs.component";
import { DateTime } from "luxon";

const form = document.getElementById("js-form-seasson");

/**
 *
 * @returns array
 */
function getFormFields() {
  return {
    from_register: { selector: ".js-check-from-register", type: "checkbox" },
    electricRecharge: { selector: ".js-check-electric", type: "checkbox" },
    city: { selector: ".js-city-select", type: "select" },
    parking: { selector: ".js-parking-select", type: "select" },
    product: { selector: ".js-product-select", type: "select" },
    frequencyDate: { selector: ".js-frequency-input", type: "date" },
    selectRenewable: { selector: ".js-renewable-select", type: "select" },
    name: { selector: ".js-form__name", type: "date" },
    lastname: { selector: ".js-form__lastname", type: "input" },
    email: { selector: ".js-form__email", type: "email" },
    phone: { selector: ".js-form__phone", type: "input" },
    docType: { selector: ".js-select-document", type: "select" },
    numDoc: { selector: ".js-num-document", type: "input" },
    business: { selector: ".js-form__business", type: "input" },
    address: { selector: ".js-form__address", type: "input" },
    town: { selector: ".js-form__town", type: "input" },
    postal: { selector: ".js-form__postal", type: "input" },
    vehicleType: { selector: ".js-form__vehicle-type", type: "select" },
    plateSelect: { selector: ".js-form__plate-select", type: "select" },
    plateInput: { selector: ".js-form__plate", type: "input" },
  };
}

function filterOptions(selectElement, optionClass, checkBoxElectric) {
  const options = selectElement.querySelectorAll(optionClass);
  options.forEach(option => {
    const isElectric = option.getAttribute('data-electric') === '1';
    option.style.display = (checkBoxElectric && !isElectric) ? 'none' : 'block';
  });

  if (selectElement.options[selectElement.selectedIndex].style.display === 'block' && !checkBoxElectric) {
    selectElement.value = "";
  }
  const unregistredText = document.querySelector(".js-unregistred-text");
  if (unregistredText && !checkBoxElectric) {
    unregistredText.classList.add("u-hidden");
  }
  const descriptionText = document.querySelector(".js-description-text");
  if (descriptionText && !checkBoxElectric) {
    descriptionText.classList.add("u-hidden");
  }
  const selectRenewable = document.querySelector(".js-renewable-select");
  if (selectRenewable && !checkBoxElectric) {
    selectRenewable.parentElement.classList.add("u-hidden");
  }
  const dateInput = document.querySelector('.js-frequency-input');
  if (dateInput && !checkBoxElectric) {
    dateInput.value = '';
    dateInput._flatpickr.clear();
  }
  const inputParking = document.querySelector('.js-parking-select');
  if (inputParking && !checkBoxElectric) {
    inputParking.disabled = true;
  }
}

function updateSelectors(event) {
  const checkBoxElectric = event.target.checked;
  const selectCity = document.getElementById('select_city');
  filterOptions(selectCity, '.js-city-option', checkBoxElectric);
}

/**
 *
 * @returns bool
 */
function isHeaderFill() {
  const fields = getFormFields();
  const elements = [
    fields.city,
    fields.parking,
    fields.product,
    fields.frequencyDate,
    fields.selectRenewable,
  ].map((f) => document.querySelector(f.selector));
  const filtered = elements.filter(
    (e) => !["", null, undefined].includes(e.value)
  );
  return filtered.length === elements.length;
}

/**
 *
 * @param {Event} event
 * @returns
 */
function scrollToWhenSelectRenewable(event) {
  if (event.currentTarget.hasAttribute("no-scroll")) {
    event.currentTarget.removeAttribute("no-scroll");
    return;
  }
  const scrollContainer = document.querySelector("[data-scroll-to]");
  const destContainer = document.getElementById(
    scrollContainer.dataset.scrollTo
  );
  const header = document.querySelector(".c-header");
  if (destContainer) {
    window.scrollTo(
      0,
      destContainer.offsetTop - destContainer.scrollTop - header.scrollHeight
    );
  }
}

/**
 *
 * @param {HTMLElement} select
 * @returns Array
 */
function getSelectedProduct(select) {
  const products = JSON.parse(select.dataset.products);

  return products.find((p) => p.uuid === select.value);
}

/* eslint-disable no-underscore-dangle */
/**
 *
 * @param {Event} event
 */
function handleCalendar(event) {
  const selectedOption = event.target.options[event.target.selectedIndex];
  if (form) {
    const frequencyDateIn = form.querySelector(
      ".js-frequency-input[name='frequency_date']"
    );

    if (selectedOption.dataset.restrictedDays.split(",").length > 0) {
      const strRestrictedDays =
        selectedOption.dataset.restrictedDays.split(",");
      const restrictedDays = strRestrictedDays.map((d) =>
        DateTime.fromISO(d).toJSDate()
      );
      const datePickerInstance = frequencyDateIn._flatpickr;
      datePickerInstance.set("disable", restrictedDays);
    }
  }
}

/**
 *
 * @param {Event} event
 */
function onChangeSelectDocument(event) {
  const divSocialInput = document.querySelector('[data-element="razonsocial"]');
  const socialInput = document.getElementsByName("razonSocial")[0];
  const selectedOption = event.target.options[event.target.selectedIndex];

  if (selectedOption && selectedOption.text === "CIF" && divSocialInput) {
    divSocialInput.classList.remove("u-hidden");
    socialInput.required = true;
  } else {
    divSocialInput.classList.add("u-hidden");
    socialInput.required = false;
  }
}

function validate() {
  const isValid = formValidator.validate(`#${form.id}`);

  return isValid && validateInputs();
}

/**
 *
 * @param {Event} event
 */
function pushProduct(event) {
  const option = event.target.options[event.target.selectedIndex];
  if (option) {
    if (option.dataset.id && option.text && option.dataset.price) {
      clickProduct(option.dataset.id, option.text, option.dataset.price);
    }
  }
}

/**
 *
 * @param {Event} event
 */
async function showProductPrice(event) {
  const option = event.target.options[event.target.selectedIndex];
  const purchaseInput = document.querySelector('[name="purchaseUuid"]');
  const priceInput = document.querySelector('[name="amount"]');
  const productPriceLabel = document.querySelector(".js-show-product");
  const priceLabel = document.querySelector(".js-show-price");
  if (!option.dataset.parking || !option.dataset.id) {
    purchaseInput.value = "";
    priceInput.value = "";
    if (productPriceLabel) {
      productPriceLabel.innerHTML = "";
    }
    if (priceLabel) {
      priceLabel.innerHTML = "- €";
    }
    return;
  }
  const priceResponse = await fetch(`${form.dataset.priceUrl}`, {
    method: "POST",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parkingId: option.dataset.parking,
      productId: option.dataset.id,
    }),
  });
  const price = await priceResponse.json();
  if (purchaseInput) {
    purchaseInput.value = price.uuid;
  }
  if (priceInput) {
    priceInput.value = price.total;
  }
  if (price && option) {
    if (option.dataset.name && option.dataset.price) {
      if (productPriceLabel && priceLabel) {
        productPriceLabel.innerHTML = option.dataset.name;
        priceLabel.innerHTML = `${parseFloat(price.total)
          .toFixed(2)
          .toString()
          .replace(".", ",")}€`;
      }
    }
  }
}

/**
 *
 * @param {Event} event
 */
function sendModalForm(event) {
  const inputReservation = document.querySelector(
    '[name="reservation-action"]'
  );
  const value = event.target.dataset.reservationAction;
  const inputComercial = document.querySelector('[name="comercial"]');
  const checkModal = document.getElementById("check-comercial");
  const inputProducts = document.querySelector(".js-product-select");

  if (inputComercial && checkModal) {
    inputComercial.value = checkModal.checked ? "on" : "";
  }

  if (inputReservation && value) {
    inputReservation.value = value;
    const isValid = formValidator.validate(`#${form.id}`);
    if (isValid) {
      if (typeof fbq !== "undefined") {
        fbq("track", "InitiateCheckout");
      }

      if (inputProducts) {
        const selectedOption =
          inputProducts.options[inputProducts.selectedIndex];
        if (
          selectedOption.dataset.id &&
          selectedOption.text &&
          selectedOption.dataset.price
        ) {
          beginCheckoutProduct(
            selectedOption.dataset.id,
            selectedOption.text,
            selectedOption.dataset.price
          );
        }
      }
      form.submit();
    }
  }
}

/**
 *
 * @param {object} formData
 * @param {string} endpoint
 * @returns
 */
function sendAjaxRequest(formData, endpoint) {
  const requesOptions = {
    method: "GET",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
  };

  const url = `${endpoint}?formData=${JSON.stringify(formData)}`;

  return new Promise((resolve) => {
    fetch(url, requesOptions)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      });
  });
}

function callValidateDoc() {
  const { docvalidationUrl } = form.dataset;
  form
    .querySelector('[data-error="numero-documento-incorrecto"]')
    .classList.add("u-hidden");
  if (document.querySelector(".js-num-document").value.length === 0) {
    return null;
  }
  return sendAjaxRequest(
    {
      documentType: document.querySelector(".js-select-document").value,
      documentNum: document.querySelector(".js-num-document").value,
    },
    docvalidationUrl
  );
}

/**
 *
 * @param {object} data
 * @returns
 */
function validateDoc(data) {
  if (data === null) {
    return false;
  }
  if (data.valid !== true) {
    form
      .querySelector('[data-error="numero-documento-incorrecto"]')
      .classList.remove("u-hidden");
    return false;
  }
  return true;
}

async function onSubmit() {
  const resultValidateDoc = await callValidateDoc();
  const isValidForm = validate();
  const isValidDoc = true;
  const modal = document.querySelector('[data-modal="login"]');
  const { isLogged } = form.dataset;
  if (!isValidForm || !isValidDoc) {
    return false;
  }

  const { recaptchaKey } = form.dataset;

  let { recaptchaUrl } = form.dataset;
  if (new URL(window.location.href).protocol === "https:") {
    recaptchaUrl = recaptchaUrl.replace("http:", "https:");
  }

  verify(
    recaptchaKey,
    recaptchaUrl,
    () => {
      document
        .querySelector('[data-error="recaptcha"]')
        .classList.add("u-hidden");
      if (modal) {
        if (isLogged === "anon") {
          modal.classList.add("is-active");
          return false;
        }
        modal.classList.remove("is-active");
      }
      form.submit();
      return true;
    },
    () => {
      document
        .querySelector('[data-error="recaptcha"]')
        .classList.remove("u-hidden");
    }
  );
  return true;
}

function loadData() {
  let data = localStorage.getItem("reservation_season");
  if (!data || data === "") {
    return;
  }
  data = JSON.parse(data);
  const fields = getFormFields();
  Object.entries(fields).forEach((value) => {
    const key = value[0];
    const field = value[1];

    if (!data[key]) {
      return;
    }

    const element = document.querySelector(field.selector);

    if (!element) {
      return;
    }

    switch (field.type) {
      case "date":
        element.value = data[key];
        break;
      case "select":
        element.selectedIndex = data[key];
        element.dispatchEvent(new CustomEvent("change"));
        break;
      case "checkbox":
        element.checked = data[key];
        element.dispatchEvent(new CustomEvent("change"));
        break;
      case "email":
        element.value = ["", null, undefined].includes(element.value)
          ? data[key]
          : element.value;
        element.dispatchEvent(new CustomEvent("change"));
        break;
      default:
        element.value = data[key];
        element.dispatchEvent(new CustomEvent("change"));
        break;
    }
  });

  localStorage.removeItem("reservation_season");
}

/**
 *
 * @param {*} fields
 * @returns
 */
function generateStorageData(fields) {
  const data = {};
  Object.entries(fields).forEach((value) => {
    const key = value[0];
    const field = value[1];
    const element = document.querySelector(field.selector);

    if (element) {
      switch (field.type) {
        case "checkbox":
          data[key] = element.checked;
          break;
        case "select":
          data[key] = element.selectedIndex;
          break;
        default:
          data[key] = element.value;
          break;
      }
    }
  });
  return data;
}

function saveFormData() {
  const fields = getFormFields();
  const data = generateStorageData(fields);
  localStorage.setItem("reservation_season", JSON.stringify(data));
}

function saveRegisterData() {
  const fields = getFormFields();
  const registerFields = {
    name: fields.name,
    lastname: fields.lastname,
    email: fields.email,
  };
  const data = generateStorageData(registerFields);
  localStorage.setItem("register", JSON.stringify(data));
}

function configureModals() {
  const btnModalRenewableRegistredOk = document.querySelector(
    ".js-renewable__ok--registred"
  );
  const btnModalRenewableRegistredCancel = document.querySelector(
    ".js-renewable__cancel--registred"
  );
  const btnModalRenewableUnRegistredOk = document.querySelector(
    ".js-renewable__ok--unregistred"
  );
  const btnModalRenewableUnRegistredCancel = document.querySelector(
    ".js-renewable__cancel--unregistred"
  );
  const checkbox = document.querySelector('input[name="renewable"]');

  if (btnModalRenewableRegistredOk) {
    btnModalRenewableRegistredOk.addEventListener("click", () => {
      checkbox.checked = true;
      checkbox.value = "on";
      saveFormData();
      onSubmit();
      return false;
    });
  }

  if (btnModalRenewableRegistredCancel) {
    btnModalRenewableRegistredCancel.addEventListener("click", () => {
      const product = getSelectedProduct(
        document.querySelector(getFormFields().product.selector)
      );

      if (!product.renewalRequired) {
        const selectRenewable = document.querySelector(
          getFormFields().selectRenewable.selector
        );
        selectRenewable.value = "0";
        checkbox.checked = false;
        checkbox.value = "off";
      }

      return false;
    });
  }

  if (btnModalRenewableUnRegistredOk) {
    btnModalRenewableUnRegistredOk.addEventListener("click", () => {
      const fromRegisterCheck = document.querySelector(
        getFormFields().from_register.selector
      );
      fromRegisterCheck.checked = true;
      saveFormData();
      saveRegisterData();
      return false;
    });
  }

  if (btnModalRenewableUnRegistredCancel) {
    btnModalRenewableUnRegistredCancel.addEventListener("click", () => {
      const product = getSelectedProduct(
        document.querySelector(getFormFields().product.selector)
      );

      if (!product.renewalRequired) {
        const selectRenewable = document.querySelector(
          getFormFields().selectRenewable.selector
        );
        selectRenewable.value = "0";
        checkbox.value = "off";
        checkbox.checked = false;
      }
    });
  }
}

function processRegistredRenewableModal() {
  const selectProduct = document.querySelector(
    getFormFields().product.selector
  );
  const formGroupRenewable = document.querySelector(
    '[data-modal="renewable-modal"]'
  );
  const modalProductName = document.querySelector(
    "#modal-renewable-product-name"
  );
  const modalProductPrice = document.querySelector(
    "#modal-renewable-product-price"
  );
  const product = getSelectedProduct(selectProduct);
  if (formGroupRenewable) {
    const btnRenewableCancel = document.querySelector(
      ".js-renewable__cancel--registred"
    );
    formGroupRenewable.classList.add("is-active");
    modalProductName.innerHTML = product.name;
    modalProductPrice.innerHTML = product.price;

    btnRenewableCancel.innerHTML = product.renewalRequired
      ? btnRenewableCancel.dataset.requiredText
      : btnRenewableCancel.dataset.unrequiredText;
  }
}

function processUnregistredRenewableModal() {
  const formGroupRenewableUnregistred = document.querySelector(
    '[data-modal="renewable-unregistred-modal"]'
  );
  const product = getSelectedProduct(
    document.querySelector(getFormFields().product.selector)
  );
  if (formGroupRenewableUnregistred) {
    const btnRenewableCancel = document.querySelector(
      ".js-renewable__cancel--unregistred"
    );
    formGroupRenewableUnregistred.classList.add("is-active");

    btnRenewableCancel.innerHTML = product.renewalRequired
      ? btnRenewableCancel.dataset.requiredText
      : btnRenewableCancel.dataset.unrequiredText;
  }
}

/**
 *
 * @param {Event} event
 */
function setRenewableView(event) {
  const product = getSelectedProduct(event.currentTarget);
  const { renewable, renewalRequired } = product;
  const selectRenewable = document.querySelector(".js-renewable-select");
  const unregistredText = document.querySelector(".js-unregistred-text");
  const frequencyInput = document.querySelector(".js-frequency-input");
  const checkbox = document.querySelector('[name="renewable"]');
  if (renewable) {
    selectRenewable.parentElement.classList.remove("u-hidden");
    selectRenewable.required = true;
    checkbox.value = "on";
    if (renewalRequired) {
      selectRenewable.setAttribute("no-scroll", true);
      selectRenewable.value = "1";
      selectRenewable.dispatchEvent(new CustomEvent("change"));
      selectRenewable.disabled = true;
    } else {
      frequencyInput.setAttribute("no-scroll", true);
      selectRenewable.removeAttribute("no-scroll", true);
      selectRenewable.selectedIndex = 0;
      selectRenewable.dispatchEvent(new CustomEvent("change"));
      selectRenewable.disabled = false;
    }
  } else {
    checkbox.value = "off";
    selectRenewable.parentElement.classList.add("u-hidden");
    selectRenewable.required = false;
    selectRenewable.disabled = false;
    selectRenewable.selectedIndex = 0;
    frequencyInput.removeAttribute("no-scroll");
  }
  if (unregistredText) {
    if (renewable) {
      unregistredText.classList.remove("u-hidden");
    } else {
      unregistredText.classList.add("u-hidden");
    }
  }
}

function loadSessionData() {
  if (form) {
    const sessionData = form.dataset.sessiondata;
    if (sessionData) {
      Object.entries(JSON.parse(sessionData)).forEach(([key, value]) => {
        const input = document.querySelector(`[name="${key}"]`);
        if (input) {
          if (input.type === "checkbox") {
            input.checked = value;
          } else {
            input.value = value;
          }
          input.dispatchEvent(new CustomEvent("change"));
        }
      });
    }
  }
}

/**
 *
 * @param {Event} event
 * @returns boolean
 */
function checkRenewable(event) {
  event.preventDefault();
  const selectRenewable = document.querySelector(
    getFormFields().selectRenewable.selector
  );
  const fromRegisterCheck = document.querySelector(
    getFormFields().from_register.selector
  );
  if (selectRenewable.value !== "1") {
    onSubmit();
    return false;
  }
  const { isLogged } = form.dataset;
  if (isLogged === "registered") {
    if (fromRegisterCheck.checked) {
      onSubmit();
      return false;
    }
    processRegistredRenewableModal();
  } else {
    processUnregistredRenewableModal();
  }
  return false;
}

async function fetchHasSeason(data) {
  const response = await fetch(`${form.dataset.hasRenewalUrl}`, {
    method: "POST",
    body: JSON.stringify(data),
  });

  return response.json();
}

function showRenewalDuplicatedModal(parkingName, carLicense) {
  const modal = document.querySelector('[data-modal="duplicated-season"]');
  if (modal) {
    const modalTextContainer = modal.querySelector(".c-modal__text");
    let modalText = modalTextContainer.innerHTML;
    modalText = modalText.replace("%parking%", parkingName);
    modalText = modalText.replace("%carLicense%", carLicense);
    modalTextContainer.innerHTML = modalText;
    modal.classList.add("is-active");
  }
}

async function showNotRenewalDuplicatedModal(
  parkingName,
  carLicense,
  mainEvent
) {
  const modal = document.querySelector(
    '[data-modal="duplicated-notRenewal-season"]'
  );
  if (modal) {
    const modalTextContainer = modal.querySelector(".c-modal__text");
    let modalText = modalTextContainer.innerHTML;
    modalText = modalText.replace("%parkingName%", parkingName);
    modalText = modalText.replace("%carLicense%", carLicense);
    modalTextContainer.innerHTML = modalText;
    modal.classList.add("is-active");
    const continueButton = modal.querySelector(
      "#duplicated-notRenewal-continue"
    );
    continueButton.addEventListener("click", (event) => {
      event.preventDefault();
      checkRenewable(mainEvent);
    });
  }
}

async function showRenewalDuplicatedContinueModal(
  parkingName,
  carLicense,
  nextRenew,
  mainEvent
) {
  const modal = document.querySelector(
    '[data-modal="duplicated-renewal-season"]'
  );
  const nextRenewDateTime = DateTime.fromISO(nextRenew, { zone: "UTC" });
  if (modal) {
    const modalTextContainer = modal.querySelector(".c-modal__text");
    let modalText = modalTextContainer.innerHTML;
    modalText = modalText.replace("%parkingName%", parkingName);
    modalText = modalText.replace("%carLicense%", carLicense);
    modalText = modalText.replace("%nextRenew%", nextRenewDateTime.toFormat("dd-MM-yyyy HH:mm"));
    modalTextContainer.innerHTML = modalText;
    modal.classList.add("is-active");
    const continueButton = modal.querySelector("#duplicated-renewal-continue");
    continueButton.addEventListener("click", (event) => {
      event.preventDefault();
      checkRenewable(mainEvent);
    });
  }
}

async function checkIfHasSeason(event) {
  event.preventDefault();
  const parkingSelector = document.querySelector(
    getFormFields().parking.selector
  );
  const carLicenseInput = document.querySelector(
    getFormFields().plateInput.selector
  );
  const startDateInput = document.querySelector(
    getFormFields().frequencyDate.selector
  );
  const startDateValue = startDateInput.value;
  const startDate = DateTime.fromFormat(startDateValue, "dd-MM-yyyy", {
    zone: "UTC",
  }).startOf('day').toISO();
  const parkingUuid = parkingSelector.value;
  const parkings = JSON.parse(parkingSelector.dataset.parkings);
  const parking = parkings.find((p) => p.uuid === parkingUuid);
  const carLicense = carLicenseInput.value;
  const { hasRenewal, hasSeason, canCreate, nextRenew } = await fetchHasSeason({
    parking: parkingUuid,
    carLicense,
    startDate,
  });

  if (false === canCreate) {
    showRenewalDuplicatedModal(parking.name, carLicense);
  } else if (true === hasSeason) {
    if (true === hasRenewal) {
      showRenewalDuplicatedContinueModal(parking.name, carLicense,nextRenew, event);
    } else {
      showNotRenewalDuplicatedModal(parking.name, carLicense, event);
    }
  } else {
    checkRenewable(event);
  }
}

function intVehicleFields() {
  const selectVehicleType = document.querySelector(
    getFormFields().vehicleType.selector
  );
  const selectPlate = document.querySelector(
    getFormFields().plateSelect.selector
  );

  if (!selectVehicleType || !selectPlate) {
    return;
  }

  selectVehicleType.addEventListener("change", (event) => {
    const selectedOption =
      event.target.options[event.target.selectedIndex].value;
    const plateSelectContainer = document.querySelector(
      '[data-element="plate-select"]'
    );
    const plateInputContainer = document.querySelector(
      '[data-element="plate-input"]'
    );
    const plateInput = document.querySelector(
      getFormFields().plateInput.selector
    );

    plateInput.value = "";
    plateInput.dispatchEvent(new Event("change"));

    if (selectedOption === "saved") {
      plateSelectContainer.classList.remove("u-hidden");
      plateInputContainer.classList.add("u-hidden");
    } else {
      plateSelectContainer.classList.add("u-hidden");
      plateInputContainer.classList.remove("u-hidden");
    }
  });

  selectPlate.addEventListener("change", (event) => {
    const selectedOption =
      event.target.options[event.target.selectedIndex].value;
    const plateInput = document.querySelector(
      getFormFields().plateInput.selector
    );

    plateInput.value = selectedOption;
    plateInput.dispatchEvent(new Event("change"));
  });
}

function toggleStartDate(event) {
  if ("" !== event.target.value) {
    const startDate = document.querySelector(
      getFormFields().frequencyDate.selector
    );
    startDate.removeAttribute("disabled");
  } else {
    const startDate = document.querySelector(
      getFormFields().frequencyDate.selector
    );
    startDate.value = "";
    startDate.setAttribute("disabled", true);
  }
}

function init() {
  const body = document.querySelector("body");
  if (body.dataset.route.match(/reservation_season/g) === null) {
    return;
  }

  const checkbox = document.querySelector(getFormFields().electricRecharge.selector);

  if (form) {
    form.addEventListener("submit", checkIfHasSeason);
    const inputProduct = document.querySelector(
      getFormFields().product.selector
    );
    const selectDocumentElement = document.querySelector(
      getFormFields().docType.selector
    );
    const selectRenewable = document.querySelector(
      getFormFields().selectRenewable.selector
    );

    if (inputProduct) {
      inputProduct.addEventListener("change", (event) => {
        showProductPrice(event);
        toggleStartDate(event);
        setRenewableView(event);
        pushProduct(event);
        handleCalendar(event);
      });
    }

    if (selectDocumentElement) {
      selectDocumentElement.addEventListener("change", onChangeSelectDocument);
    }

    const btnReservationsAction = document.querySelectorAll(
      "[data-reservation-action]"
    );

    if (btnReservationsAction && btnReservationsAction.length > 0) {
      btnReservationsAction.forEach((btn) => {
        btn.addEventListener("click", sendModalForm);
      });
    }

    selectRenewable.addEventListener("change", (event) => {
      const renewable = document.querySelector('[name="renewable"]');
      renewable.checked = event.currentTarget.value === "1";
      renewable.value = event.currentTarget.value === "1" ? "on" : "off";

      if (isHeaderFill()) {
        scrollToWhenSelectRenewable(event);
      }
    });

    intVehicleFields();
  }

  if (checkbox) {
    checkbox.addEventListener('change', updateSelectors);
  }

  configureModals();
  loadData();
  loadSessionData();
}

export default init();
